<template>
  <el-container :lang="$i18n.locale">
    <!--<el-header>
      <div class="background"></div>
      <div class="backgroundContent">
        <font-awesome-icon :icon="['fac', 'logo']" />
      </div>
      <div class="backgroundContentFill">
        <font-awesome-icon :icon="['fac', 'logo']" />
      </div>
      <div class="title">
        <h1>{{ $t('views.home.title') }}</h1>
      </div>
    </el-header>-->
    <el-main>
      <el-space wrap :style="{ gap: 0 }">
        <router-link
          :to="`/quiz/${item.id}?locale=${locale}`"
          v-for="item in list"
          :key="item.id"
          :style="{ '--color': item.settings.color }"
        >
          <div>
            <h2>
              <font-awesome-icon :icon="item.settings.icon" />
            </h2>
            <br />
            <h3>{{ item.title }}</h3>
            <!--<div v-if="item.description">{{ item.description }}</div>-->
          </div>
        </router-link>
      </el-space>
    </el-main>
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as quizService from '@/services/quiz-service';
import { Quiz } from '@/types/api/Quiz';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as authService from '@/services/auth-service';

@Options({
  components: { FontAwesomeIcon },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class HomeView extends Vue {
  @Prop({ default: 'de' }) readonly locale!: string;
  list: Quiz[] = [];

  mounted(): void {
    authService.setLocale(this.locale, this.$i18n as any);

    //quizService.setIDs(true, this.locale);
    quizService.getQuizList(this.locale).then((list) => {
      this.list = list;
    });
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  width: 100vw;
  height: 100dvh;
}

.el-header {
  --el-header-padding: 0;
  --el-header-height: 50vh;
}

.el-main {
  position: relative;
}

.el-space {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .el-header {
    --el-header-height: 0;
  }

  .el-space::v-deep(.el-space__item) {
    width: 50%;
    height: 25%;
  }
}

@media (min-width: 1024px) {
  .el-header {
    --el-header-height: 50vh;
  }

  .el-space::v-deep(.el-space__item) {
    width: 25%;
    height: 50%;
  }
}

/*@media (min-width: 512px) and (max-width: 1024px) {
  .el-header {
    --el-header-height: 50vh;
  }

  .el-space::v-deep(.el-space__item) {
    width: 25%;
    height: 50%;
  }
}

@media (min-width: 1024px) {
  .el-header {
    --el-header-height: 75vh;
  }

  .el-space::v-deep(.el-space__item) {
    width: 12.5%;
    height: 100%;
  }
}*/

a {
  height: calc(100% - 2rem);
  background-color: var(--color);
  color: white;
  text-decoration-line: none;
  font-size: var(--font-size-default);
  padding: 1rem;
  display: flex;
  text-align: center;
  overflow: hidden;

  > div {
    margin: auto;
    vertical-align: center;
  }

  h2 {
    display: inline;
    font-weight: var(--font-weight-semibold);
    font-size: 2.5rem;
    color: white;
  }

  h3 {
    hyphens: auto;
    //line-height: 1rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0;
    color: white;
  }
}

a:visited,
a:hover {
  color: white;
}

.el-main {
  --el-main-padding: 0;
}

.background {
  background-image: url('@/assets/img/water.png');
  background-repeat: repeat-x;
  background-size: 5rem;
  background-position-y: calc(100% + 1.1rem);
  background-position-x: 2.5rem;
  width: 100vw;
  height: 90vh;
  background-color: var(--color-main);
  transform: rotate(180deg);
  position: fixed;
  bottom: 0;
}

.backgroundContent,
.backgroundContentFill {
  color: white;
  font-size: 3rem;
  height: unset;
  position: fixed;
  bottom: calc(90vh - 0.4rem);
  left: 2rem;
}

.title {
  position: fixed;
  top: 25vh;
  left: 3.1rem;

  h1 {
    margin: 0;
    font-size: var(--font-size-h1);
    color: white;
  }
}

.backgroundContent .fa-logo::v-deep(path) {
  stroke: var(--color-main);
  stroke-width: 3rem;
  stroke-linejoin: round;
}
</style>
